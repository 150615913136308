import React from "react";
import $ from "jquery";

class Navbar extends React.Component {

  componentDidMount() {

    $(window).scroll(function () {
      if ($(this).scrollTop() > 45) {
        $('.navbar').addClass('sticky-top shadow-sm');
      } else {
        $('.navbar').removeClass('sticky-top shadow-sm');
      }
    })
  }

  render() {


    return (
      <div>
        {/* <!-- Topbar Start --> */}
        <div className="container-fluid bg-dark px-5 d-none d-lg-block">
          <div className="row gx-0">
            <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
              <div className="d-inline-flex align-items-center" style={{ height: '45px' }}>
                <small className="me-3 text-light"><i className="fbi bi-clock-fill me-2"></i>Monday - Sunday (8:00 am - 6:00 pm)</small>
              </div>
            </div>
            <div className="col-lg-4 text-center text-lg-end">
              <div className="d-inline-flex align-items-center" style={{ height: '45px' }}>
                <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://www.instagram.com/intentional_tuitions/"><i className="fab fa-instagram fw-normal"></i></a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Topbar End --> */}

        {/* <!-- Navbar Start --> */}
        <div className="container-fluid position-relative p-0">
          <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
            <a href="/" className="navbar-brand p-0">
              <div class="nav-logo"></div>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="fa fa-bars"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto py-0">
                <a href="/" className="nav-item nav-link">Home</a>
                <a href="/aboutus" className="nav-item nav-link">About</a>
                <a href="/services" className="nav-item nav-link">Services</a>
                <a href="/contact-us" className="nav-item nav-link">Contact</a>
                <a href="/faq" className="nav-item nav-link">FAQs</a>
              </div>

            </div>
          </nav>

        </div>
        {/* <!-- Navbar End --> */}

      </div>
    );
  }
}

export default Navbar;
