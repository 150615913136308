import React from "react";

class Info extends React.Component {
    render() {
        return (
            <div>
                <div id="info" className="container-fluid py-5 mt-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container py-2">
                        <div className="row g-5">
                            <div className="col-lg-7">
                                <div className="section-title position-relative pb-3 mb-3">
                                    <h5 className="fw-bold text-primary text-uppercase">PRIVATE TUITION SERVICES</h5>
                                </div>
                                <h2 className="mb-4">From core subjects to specialized areas, we offer comprehensive
                                    tutoring that empowers students to excel.
                                </h2>
                                <div className="row g-5 mb-3 p-4">
                                    <div className="col-sm-4 wow zoomIn" data-wow-delay="0.2s">
                                        <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Early Years</h5>
                                        <p className="mb-3 ml-4">Expert tutoring tailored for the crucial developmental stages from infancy to preschool, ensuring a strong foundation for lifelong learning.</p>
                                    </div>
                                    <div className="col-sm-4 wow zoomIn" data-wow-delay="0.4s">
                                        <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Primary</h5>
                                        <p className="mb-3">Comprehensive support for primary school students, fostering academic growth and confidence in core subjects and beyond.</p>
                                    </div>
                                    <div className="col-sm-4 wow zoomIn" data-wow-delay="0.4s">
                                        <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Secondary</h5>
                                        <p className="mb-3">Specialized guidance for secondary students, empowering them to excel in diverse subjects and navigate academic challenges successfully.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5" style={{ minHeight: '300px' }}>
                                <div className="position-relative h-100">
                                    <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/teacher-tutor.jpg" style={{ objectFit: 'cover' }} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Info;
