
function Footer() {
  return (
    <div>
      {/* Start footer area */}
      <div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="flex-column align-items-center text-center p-4">
                <a href="/" className="navbar-brand">
                  <img className="w-50 m-4 p-2" src="assets/logo-light.png" alt="logo" />
                </a>
                <p className="mt-0 mb-4">Empowering students through personalized learning. Join us in shaping futures.</p>
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-6 col-md-12 pt-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2"></i>
                    <p className="mb-0">Intentionalnairobituition@gmail.com</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2"></i>
                    <p className="mb-0">+254-715-711-351</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2"></i>
                    <p className="mb-0">+254-703-291-007</p>
                  </div>
                  <div className="d-flex mt-4">
                    <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                    <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                    <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                    <a className="btn btn-primary btn-square" href="https://www.instagram.com/intentional_tuitions/"><i className="fab fa-instagram fw-normal"></i></a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <a className="text-light mb-2" href="/"><i className="bi bi-arrow-right text-primary me-2"></i>Home</a>
                    <a className="text-light mb-2" href="/aboutus"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</a>
                    <a className="text-light mb-2" href="/services"><i className="bi bi-arrow-right text-primary me-2"></i>Our Services</a>
                    <a className="text-light mb-2" href="/contact-us"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                    <a className="text-light" href="/faq"><i className="bi bi-arrow-right text-primary me-2"></i>FAQs</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid text-white" style={{ background: '#061429' }}>
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div className="d-flex align-items-center" style={{ height: '75px' }}>
                <p className="mb-0">&copy; <a className="text-white border-bottom" href="#">Intentional Tuition Center</a>. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end footer bottom */}
    </div>
  );
}

export default Footer;
