// import logo from './logo.svg';
import './App.css';
import React from 'react';
import Home from './components/home/Home'
import Aboutus from './components/aboutus/Aboutus'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Service from './components/services/Service';
import Contact from './components/contact/Contact';
import Faq from './components/faq/Faq';
import Spinner from './components/scroll/Spinner';
import ScrollToTop from './components/scroll/ScrollToTop';

class App extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <div>
            <Spinner />
            <ScrollToTop />
            <Routes>
              <Route exact path="/" Component={Home} />
              <Route exact path="/aboutus" Component={Aboutus} />
              <Route exact path="/services" Component={Service} />
              <Route exact path="/contact-us" Component={Contact} />
              <Route exact path="/faq" Component={Faq} />
            </Routes>
          </div>

        </Router>
      </div>

    );
  }
}

export default App;
