import React from "react";
import OwlCarousel from "react-owl-carousel";

class Curriculums extends React.Component {
    render() {
        return (
            <div>
                {/* <!-- Curriculums Start --> */}
                <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
                        <h5 className="fw-bold text-primary text-uppercase">CURRICULUMS OFFERED</h5>
                        <p className="mb-3 text-dark">We offer tuition services on various curriculums such British, American including those from IGCSE, IB, GCSE,
                         A-LEVEL, ACE, SATs, and the Kenyan curriculum (KCPE, KCSE).</p>
                    </div>
                    <div Name="container py-5 mb-5">
                        <div className="container bg-white">
                            <OwlCarousel
                                items={5}
                                margin={40}
                                autoplay={false}
                                loop={false}
                                startPosition={0}
                                dots={true}
                                smartSpeed={1000} className="testimonial-carousel owl-carousel owl-theme" responsive={{
                                    0: {
                                        items: 2,
                                        margin: 0
                                    },
                                    576: {
                                        items: 2
                                    },
                                    768: {
                                        items: 3
                                    },
                                    992: {
                                        items: 4
                                    },
                                    1200: {
                                        items: 5
                                    }
                                }}>
                                <img src="assets/cambridge.png" alt="" style={{ height: '100px', width: '200px' }} />
                                <img src="assets/edexcel-logo.png" alt="" style={{ height: '110px', width: '190px' }} />
                                <img src="assets/K12Logo_4C.jpg" alt="" style={{ height: '110px', width: '200px' }} />
                                <img src="assets/IB-logo.jpeg" alt="" style={{ height: '110px', width: '210px' }} />
                                <img src="assets/knec-logo.png" alt="" style={{ height: '100px', width: '100px' }} />
                                {/* <img src="assets/IB_LOGO.png" alt="" style={{ height: '100px', width: '100px' }} /> */}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
                {/* <!-- Curriculums End --> */}
            </div >
        );
    }
}

export default Curriculums;
