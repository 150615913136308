import React from "react";

class Services extends React.Component {
    render() {
        return (
            <div>
                {/* <!-- Services Start --> */}
                <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container py-5">
                        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
                            <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
                            <h1 className="mb-0">Tailored Learning Solutions for a Brighter Future</h1>
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                                <div
                                    className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center">
                                    <div className="service-image">
                                        <img src="assets/private-tuition.jpg" alt="Personalized Studies & Private Tuition" />
                                    </div>
                                    <div style={{ padding: '0 25px' }}>
                                        <h4 className="mb-3">Personalized Studies & Private Tuition</h4>
                                        <p className="mb-3 pb-5">Tailored academic support to meet individual learning needs, ensuring students reach their full potential in a personalized setting.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                                <div
                                    className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center">
                                    <div className="service-image">
                                        <img src="assets/home-schooling.jpg" alt="Personalized Studies & Private Tuition" />
                                    </div>
                                    <div style={{ padding: '0 25px' }}>
                                        <h4 className="mb-3">Home-Schooling Support
                                        </h4>
                                        <p className="mb-3 pb-5">Expert guidance and resources for parents and students navigating the home-schooling journey, ensuring a well-rounded and effective educational experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                                <div
                                    className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center">
                                    <div className="service-image">
                                        <img src="assets/special-needs.jpg" alt="Personalized Studies & Private Tuition" />
                                    </div>
                                    <div style={{ padding: '0 25px' }}>
                                        <h4 className="mb-3">Special Needs Education</h4>
                                        <p className="mb-3 pb-5">Specialized care and education for students with diverse learning needs, fostering an inclusive and supportive learning environment.
                                        </p><br/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                                <div
                                    className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center">
                                    <div className="service-image">
                                        <img src="assets/test-prep.jpg" alt="Personalized Studies & Private Tuition" />
                                    </div>
                                    <div style={{ padding: '0 25px' }}>
                                        <h4 className="mb-3">Standardized Test Prep</h4>
                                        <p className="mb-3 pb-5">Comprehensive preparation programs for standardized tests, equipping students with the skills and confidence to excel in their exams.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                                <div
                                    className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center">
                                    <div className="service-image">
                                        <img src="assets/extracurricular-activities.jpg" alt="Personalized Studies & Private Tuition" />
                                    </div>
                                    <div style={{ padding: '0 25px' }}>
                                        <h4 className="mb-3">Extracurricular Activities</h4>
                                        <p className="mb-3 pb-5">Engaging and enriching activities beyond the classroom, promoting holistic development and a well-rounded educational experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                                <div
                                    className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center">
                                    <div className="service-image">
                                        <img src="assets/career-counseling.jpg" alt="Personalized Studies & Private Tuition" />
                                    </div>
                                    <div style={{ padding: '0 25px' }}>
                                        <h4 className="mb-3">Career Counseling</h4>
                                        <p className="mb-3 pb-5">Professional guidance and support to help students explore career options, set goals, and make informed decisions about their future.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Services End --> */}
            </div>
        );
    }
}

export default Services;