import React from "react";

class AboutInfo extends React.Component {
    render() {
        return (
            <div>
                <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container py-5">
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="section-title position-relative pb-3 mb-5">
                                    <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
                                    <h1 className="mb-0">About Intentional Tuition Center</h1>
                                </div>
                                <p className="mb-4">INTENTIONAL is a dynamic learning ecosystem designed to empower students of all backgrounds and abilities to thrive in a rapidly changing world.  We believe in personalized learning, tailoring education to each child’s unique needs and aspirations.
                                    Our passionate and experienced teachers create engaging and effective lesson plans that cater to individual learning styles. Whether your child thrives with visual aids, hands-on activities, or in-depth discussions, we tailor the experience to fuel their understanding and confidence.
                                </p>
                                <div className="row g-0 mb-3">
                                    <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                        <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Expert Faculty</h5>
                                        <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Innovative Approach</h5>
                                    </div>
                                    <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                        <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Holistic Development</h5>
                                        <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Flexible Scheduling</h5>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
                                    <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-phone-alt text-white"></i>
                                    </div>
                                    <div className="ps-4">
                                        <h5 className="mb-2">Call our Learning Supervisor</h5>
                                        <h4 className="text-primary mb-0">+254-703-291-007</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" style={{ minHeight: '500px' }}>
                                <div className="position-relative h-100">
                                    <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/about.jpg" style={{ objectFit: 'cover' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutInfo;
