import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

class Contact extends React.Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
                    <div className="row py-5">
                        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 className="display-4 text-white animated zoomIn">Contact</h1>
                            <a href="/" className="h5 text-white">Home</a>
                            <i className="far fa-circle text-white px-2"></i>
                            <a href="#" className="h5 text-white">Contact</a>
                        </div>
                    </div>

                </div>
                {/* <!-- Contact Start --> */}
                <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container py-5">
                        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
                            <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
                            <h1 className="mb-0">Start Your Learning Journey With Us Today</h1>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-4">
                                <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                                    <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: "40px", height: "40px" }}>
                                        <i className="fa fa-phone-alt text-white"></i>
                                    </div>
                                    <div className="ps-3">
                                        <h5 className="mb-2">Phone</h5>
                                        <h4 className="text-primary mb-0">+254-715-711-351</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                                    <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: "40px", height: "40px" }}>
                                        <i className="fa fa-map-marker-alt text-white"></i>
                                    </div>
                                    <div className="ps-3">
                                        <h5 className="mb-2">Location</h5>
                                        <h4 className="text-primary mb-0">Rosters,H7,Muthaiga North Drive</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
                                    <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: "40px", height: "40px" }}>
                                        <i className="fa fa-envelope-open text-white"></i>
                                    </div>
                                    <div className="ps-3">
                                        <h5 className="mb-2">Email</h5>
                                        <h4 className="text-primary mb-0">Intentionalnairobituition@gmail.com</h4>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        {/* <div className="row">
                            <div className="slideInUp" data-wow-delay="0.6s" style={{ marginTop: "30px" }}>
                                <iframe className="position-relative rounded w-100 h-100"
                                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1994.4217213482902!2d36.799301331173055!3d-1.2666159998998388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMcKwMTUnNTkuOCJTIDM2wrA0OCcwMC4xIkU!5e0!3m2!1sen!2ske!4v1714482468602!5m2!1sen!2ske"
                                    frameborder="0" style={{ minHeight: "400px", border: 0 }} allowfullscreen="" aria-hidden="false"
                                    tabindex="0"></iframe>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <!-- Contact End --> */}
                <Footer />
            </div>
        );
    }
}

export default Contact;
