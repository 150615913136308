import React from "react";

class Team extends React.Component {
    render() {
        return (
            <div>
                {/* <!-- Team Start --> */}
                <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container py-5">
                        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
                            <h5 className="fw-bold text-primary text-uppercase">Team Members</h5>
                            <h1 className="mb-0">Meet Our Passionate Team Driving Our Vision Forward</h1>
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                                <div className="team-item bg-light rounded overflow-hidden">
                                    <div className="team-img position-relative overflow-hidden">
                                        <img className="img-fluid w-100" src="assets/team-1.jpg" alt="" />
                                        <div className="team-social">
                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                                        </div>
                                    </div>
                                    <div className="text-center py-4">
                                        <h4 className="text-primary">Full Name</h4>
                                        <p className="text-uppercase m-0">Designation</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                                <div className="team-item bg-light rounded overflow-hidden">
                                    <div className="team-img position-relative overflow-hidden">
                                        <img className="img-fluid w-100" src="assets/team-2.jpg" alt="" />
                                        <div className="team-social">
                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                                        </div>
                                    </div>
                                    <div className="text-center py-4">
                                        <h4 className="text-primary">Full Name</h4>
                                        <p className="text-uppercase m-0">Designation</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                                <div className="team-item bg-light rounded overflow-hidden">
                                    <div className="team-img position-relative overflow-hidden">
                                        <img className="img-fluid w-100" src="assets/team-3.jpg" alt="" />
                                        <div className="team-social">
                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                                        </div>
                                    </div>
                                    <div className="text-center py-4">
                                        <h4 className="text-primary">Full Name</h4>
                                        <p className="text-uppercase m-0">Designation</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Team End --> */}
            </div>
        );
    }
}

export default Team;
