import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import AboutInfo from "./AboutInfo";
import Team from "./Team";
import Features from "../features/Features";

class Aboutus extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">About Us</h1>
              <a href="/" className="h5 text-white">Home</a>
              <i className="far fa-circle text-white px-2"></i>
              <a href="#" className="h5 text-white">About</a>
            </div>
          </div>
        </div>
        <AboutInfo />
        <Features />
        {/* <Team /> */}
        <Footer />

      </div>
    );
  }
}

export default Aboutus;
