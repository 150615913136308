import React, { useState, useEffect } from 'react';

function ScrollUpButton() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top when button is clicked
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <button
      className={`btn btn-primary rounded-circle ${isVisible ? 'visible' : 'invisible'}`}
      onClick={scrollToTop}
      style={{
        position: 'fixed',
        bottom: '25px',
        right: '25px',
        zIndex: '1000'
      }}
    >
      <i className="bi bi-arrow-up"></i>
    </button>
  );
}

export default ScrollUpButton;
