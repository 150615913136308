import React from "react";

class HomeHeaderCarousel extends React.Component {

  render() {
    return (
      <div>
        {/* <!-- Carousel Start --> */}
        <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100" src="assets/carousel-1.jpg" alt="" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: '900px' }}>
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">Home-based and Online</h5>
                  <h1 className="display-2 text-white mb-md-4 animated zoomIn">Tuition Services</h1>
                  <a href="#info" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Read More</a>
                  <a href="/contact-us" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Carousel End --> */}

      </div>
    );
  }
}

export default HomeHeaderCarousel;
