import React from "react";
import OwlCarousel from "react-owl-carousel";

class Testimonials extends React.Component {

    render() {
        return (
            <div>
                {/* <!-- Testimonial Start --> */}
                <div className="container-fluid py-5 wow fadeInUp testimonial-area" data-wow-delay="0.1s">
                    <div className="container py-5 testimonial-box">
                        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
                            <h5 className="fw-bold text-primary text-uppercase">Testimonial</h5>
                            <h1 className="mb-0">What Our Clients Say About Us</h1>
                        </div>

                        <OwlCarousel
                            items={1}
                            margin={40}
                            autoplay={true}
                            loop={true}
                            startPosition={0}
                            smartSpeed={4000} className="testimonial-carousel owl-carousel owl-theme" data-wow-delay="0.6s">

                            <div className="single-testimonial-item text-center">
                                <div className="image-box">
                                    <img
                                        src="assets/testimonial1.png"
                                        alt=""
                                    />
                                    <div className="icon">
                                        <span className="bi bi-chat-left-quote-fill"></span>
                                    </div>
                                </div>
                                <div className="text-box">
                                    <p>
                                        "I have benefited greatly from the input of Intentional Tuition Centre on my child’s academic path;
                                        particularly their individualized course options and ingenious pedagogic techniques which are the best.
                                        I highly recommend them!"
                                    </p>
                                </div>
                                <div className="client-name">
                                    <h5>Wanjiku Mwangi</h5>
                                </div>
                            </div>

                            <div className="single-testimonial-item text-center">
                                <div className="image-box">
                                    <img src="assets/testimonial2.png" alt="" />
                                    <div className="icon">
                                        <span className="bi bi-chat-left-quote-fill"></span>
                                    </div>
                                </div>
                                <div className="text-box">
                                    <p>
                                        "At Intentional Tuition Center, I have seen first-hand how dedicated the staff is as a parent.
                                        What they do to ensure students have a conducive learning environment is nothing short of commendable.
                                        My child has really done well under their guidance."
                                    </p>
                                </div>
                                <div className="client-name">
                                    <h5>Juma Hassan</h5>
                                </div>
                            </div>

                            <div className="single-testimonial-item text-center">
                                <div className="image-box">
                                    <img
                                        src="assets/testimonial3.png"
                                        alt=""
                                    />
                                    <div className="icon">
                                        <span className="bi bi-chat-left-quote-fill"></span>
                                    </div>
                                </div>
                                <div className="text-box">
                                    <p>
                                        "The Intentional Tuition Center team always makes sure that every student gets the kind
                                        of education that suits them by going the extra mile, hence its unmatched holistic approach to the same."
                                    </p>
                                </div>
                                <div className="client-name">
                                    <h5>Njoroge Mugo</h5>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
                {/* <!-- Testimonial End --> */}
            </div >
        );
    }
}

export default Testimonials;
