import React from "react";

class Features extends React.Component {

    render() {
        return (
            <div>
                {/* <!-- Features Start --> */}
                <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="container py-5">
                        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
                            <h5 class="fw-bold text-primary text-uppercase">Why Choose Us</h5>
                            <h1 class="mb-0">Your trusted partner for exceptional education and growth</h1>
                        </div>
                        <div class="row g-5">
                            <div class="col-lg-4">
                                <div class="row g-5">
                                    <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                                        <div class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                                            style={{ width: "60px", height: "60px" }}>
                                            <i class="bi bi-journal-bookmark-fill text-white"></i>
                                        </div>
                                        <h4>Personalized Learning Approach</h4>
                                        <p class="mb-0">Tailored curriculum and individualized attention ensure each student reaches their full potential</p>
                                    </div>
                                    <div class="col-12 wow zoomIn" data-wow-delay="0.6s">
                                        <div class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                                            style={{ width: "60px", height: "60px" }}>
                                            <i class="bi bi-person-check-fill text-white"></i>
                                        </div>
                                        <h4>Experienced and Qualified Staff</h4>
                                        <p class="mb-0">Highly experienced educators passionate about student success, undergo regular training for professional development</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 wow zoomIn" data-wow-delay="0.9s" style={{ minHeight: '350px' }}>
                                <div class="position-relative h-100">
                                    <img class="position-absolute w-100 h-100 rounded wow zoomIn" alt="" data-wow-delay="0.1s"
                                        src="assets/feature.jpg" style={{ objectFit: "cover" }} />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="row g-5">
                                    <div class="col-12 wow zoomIn" data-wow-delay="0.4s">
                                        <div class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                                            style={{ width: "60px", height: "60px" }}>
                                            <i class="fa fa-rocket text-white"></i>
                                        </div>
                                        <h4>Innovative Teaching Methods</h4>
                                        <p class="mb-0">Cutting-edge technology and project-based learning enhance engagement and critical thinking</p>
                                    </div>
                                    <div class="col-12 wow zoomIn" data-wow-delay="0.8s">
                                        <div class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                                            style={{ width: "60px", height: "60px" }}>
                                            <i class="fa fa-school text-white"></i>
                                        </div>
                                        <h4>Supportive Learning Environment</h4>
                                        <p class="mb-0">Warm, welcoming atmosphere prioritizes student well-being</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Features Start --> */}
            </div>
        );
    }
}

export default Features;
