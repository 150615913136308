import React from "react";

class Modes extends React.Component {

    render() {
        return (
            <div>
                {/* <!-- Modes Start --> */}
                <div class="container py-0 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="container py-5">
                        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
                            <h1 class="mb-0">Modes of Tuition</h1>
                        </div>
                        <div class="row g-5">
                            <div className="row g-5 mb-4">
                                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.3s">
                                    <div
                                        className="mode-item rounded d-flex flex-column align-items-center justify-content-center text-center">
                                        <div className="mode-icon">
                                            <i className="bi bi-house-fill text-white"></i>
                                        </div>
                                        <h4 className="mb-3">Home-based schooling</h4>
                                    </div>
                                </div>
                                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.6s">
                                    <div
                                        className="mode-item rounded d-flex flex-column align-items-center justify-content-center text-center">
                                        <div className="mode-icon">
                                            <i className="bi bi-globe text-white"></i>
                                        </div>
                                        <h4 className="mb-3">Online tuition</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Modes Start --> */}
            </div>
        );
    }
}

export default Modes;
