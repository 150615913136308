import React from "react";
import Navbar from "../navbar/Navbar";
import HomeHeaderCarousel from "../navbar/Home-header-carousel"
import Footer from "../footer/Footer";
import Facts from "./Facts";
import AboutInfo from "../aboutus/AboutInfo";
import Features from "../features/Features";
import Services from "../services/Services";
import Testimonials from "../testimonials/Testimonials";
import Curriculums from "../curriculum/Curriculums";
import Info from "../Intro/Info";
import Modes from "../Modes/Modes";

class Home extends React.Component {

  render() {
    return (
      <div>
        <Navbar />
        <HomeHeaderCarousel />
        {/* <Facts /> */}
        <Info />
        <Modes />
        <Curriculums />
        <Services />
        <Testimonials />
        <Footer />
      </div>
    );
  }
}

export default Home;
