import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import $ from "jquery";


class Faq extends React.Component {
    componentDidMount() {
        $(function () {
            $('.accordion').find('.accordion-title').on('click', function () {
                // Adds Active Class
                $(this).toggleClass('active');
                // Expand or Collapse This Panel
                $(this).next().slideToggle('fast');
                // Hide The Other Panels
                $('.accordion-content').not($(this).next()).slideUp('fast');
                // Removes Active Class From Other Titles
                $('.accordion-title').not($(this)).removeClass('active');
            });
        });
    }
    render() {
        return (
            <div>
                <Navbar />
                <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
                    <div className="row py-5">
                        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 className="display-4 text-white animated zoomIn">Faq</h1>
                            <a href="/" className="h5 text-white">Home</a>
                            <i className="far fa-circle text-white px-2"></i>
                            <a href="#" className="h5 text-white">Faq</a>
                        </div>
                    </div>
                </div>
                {/* <!-- Start FAQ Area --> */}
                {/* <section className="faq-area ptb-100"> */}
                <div className="container py-5">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
                        <h5 className="fw-bold text-primary text-uppercase">Faqs</h5>
                        <h1 className="mb-0">General Inquiries</h1>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 " >
                                <div className="acc2">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-One">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-One" aria-expanded="true" aria-controls="collapse-One">
                                                    What makes INTENTIONAL different from other tuition centers?
                                                </button>
                                            </h2>
                                            <div id="collapse-One" className="accordion-collapse collapse" aria-labelledby="heading-One" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    At INTENTIONAL, we go beyond a one-size-fits-all approach. We offer personalized learning with experienced teachers who create lesson plans specifically tailored to your child’s needs and learning style. We also offer flexible learning options, including in-home and virtual tutoring, and cater to a wide range of curriculums and student needs, including homeschooling and students of determination.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-Two">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-Two" aria-expanded="false" aria-controls="collapse-Two">
                                                    What subjects do you offer tutoring in?
                                                </button>
                                            </h2>
                                            <div id="collapse-Two" className="accordion-collapse collapse" aria-labelledby="heading-Two" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    We offer tutoring in a wide range of subjects, from core subjects like Math, English, Science, and Social Studies to more specialized subjects depending on your child’s needs.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-Three">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-Three" aria-expanded="false" aria-controls="collapse-Three">
                                                    Do you offer tutoring for students of determination?
                                                </button>
                                            </h2>
                                            <div id="collapse-Three" className="accordion-collapse collapse" aria-labelledby="heading-Three" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    Absolutely! We believe every child deserves the opportunity to reach their full potential. We collaborate with renowned institutions to develop personalized learning plans for students with diverse learning challenges.                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-Four">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-Four" aria-expanded="false" aria-controls="collapse-Four">
                                                    Do you offer in-home or online tutoring?
                                                </button>
                                            </h2>
                                            <div id="collapse-Four" className="accordion-collapse collapse" aria-labelledby="heading-Four" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    Yes, we offer both in-home and online tutoring options to best suit your family’s needs and preferences.                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-Five">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-Five" aria-expanded="false" aria-controls="collapse-Five">
                                                    What is the benefit of in-home tutoring?
                                                </button>
                                            </h2>
                                            <div id="collapse-Five" className="accordion-collapse collapse" aria-labelledby="heading-Five" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    Commercial management in construction ensures the planning, execution, and coordination of a construction project from the start to finish. These are often for specific projects such as building or renovation projects that are sold or leased.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-Six">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-Six" aria-expanded="false" aria-controls="collapse-Six">
                                                    Prepare a construction project schedule?
                                                </button>
                                            </h2>
                                            <div id="collapse-Six" className="accordion-collapse collapse" aria-labelledby="heading-Six" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    Online tutoring offers flexibility for busy schedules and allows students to connect with experienced teachers anywhere in the world. It also promotes valuable technology skills.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* part 2 */}
                            <div className="col-lg-6" >
                                <div className="acc2">
                                    <div className="accordion" id="accordionExample2">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-Seven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-Seven" aria-expanded="false" aria-controls="collapse-Seven">
                                                    Do you support homeschooling?
                                                </button>
                                            </h2>
                                            <div id="collapse-Seven" className="accordion-collapse collapse" aria-labelledby="heading-Seven" data-bs-parent="#accordionExample2">
                                                <div className="accordion-body">
                                                    Yes, we offer comprehensive homeschooling support. Our experienced teachers can help you create customized learning programs, navigate different curriculums, and provide expert guidance throughout your homeschooling journey.                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-Eight">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-Eight" aria-expanded="false" aria-controls="collapse-Eight">
                                                    How are lessons tailored to my child’s needs?
                                                </button>
                                            </h2>
                                            <div id="collapse-Eight" className="accordion-collapse collapse" aria-labelledby="heading-Eight" data-bs-parent="#accordionExample2">
                                                <div className="accordion-body">
                                                    Our experienced teachers will assess your child’s individual strengths, weaknesses, learning styles, and academic goals. They will then create personalized lesson plans that address your child’s specific needs and ensure they progress at their own pace.                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-Nine">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-Nine" aria-expanded="false" aria-controls="collapse-Nine">
                                                    How will I be informed of my child’s progress?
                                                </button>
                                            </h2>
                                            <div id="collapse-Nine" className="accordion-collapse collapse" aria-labelledby="heading-Nine" data-bs-parent="#accordionExample2">
                                                <div className="accordion-body">
                                                    We believe in open communication. You will receive regular progress reports outlining your child’s achievements and areas for continued development. We also encourage ongoing communication between you and your child’s teacher.                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-Ten">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-Ten" aria-expanded="false" aria-controls="collapse-Ten">
                                                    What qualifications do your teachers have?
                                                </button>
                                            </h2>
                                            <div id="collapse-Ten" className="accordion-collapse collapse" aria-labelledby="heading-Ten" data-bs-parent="#accordionExample2">
                                                <div className="accordion-body">
                                                    Our teachers are passionate educators with extensive experience and relevant certifications. They are dedicated to creating a positive and engaging learning environment for your child.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-Eleven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-Eleven" aria-expanded="false" aria-controls="collapse-Eleven">
                                                    How do I get started?
                                                </button>
                                            </h2>
                                            <div id="collapse-Eleven" className="accordion-collapse collapse" aria-labelledby="heading-Eleven" data-bs-parent="#accordionExample2">
                                                <div className="accordion-body">
                                                    Contact us today for a free consultation! We’ll discuss your child’s needs and answer any questions you may have.                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-Twelve">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-Twelve" aria-expanded="false" aria-controls="collapse-Twelve">
                                                    Do you offer discounts or packages?
                                                </button>
                                            </h2>
                                            <div id="collapse-Twelve" className="accordion-collapse collapse" aria-labelledby="heading-Twelve" data-bs-parent="#accordionExample2">
                                                <div className="accordion-body">
                                                    Yes, we offer a variety of tuition packages to fit your needs and budget. Please contact us for more information.                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end part 2 */}
                        </div>
                    </div>
                </div>
                {/* </section> */}
                {/* <!-- End FAQ Area --> */}
                <Footer />
            </div>
        );
    }
}

export default Faq;
